/**
 * NetworkService exposes network status of current browser.
 */

import { BehaviorSubject } from 'rxjs';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Injectable, NgZone, inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  public connectionStatus = new BehaviorSubject<ConnectionStatus | null>(null);
  private readonly ngZone: NgZone = inject(NgZone);

  constructor() {
    this.getCurrentStatus();
  }

  /*
   * Init listener for network status changes
   */
  public initializeNetworkEvents(): void {
    Network.removeAllListeners();
    Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
      this.ngZone.run(() => {
        this.connectionStatus.next(status);
      });
    });
  }

  /*
   * Getter method to retrieve current network status
   */
  public async getCurrentStatus(): Promise<boolean> {
    const status = await Network.getStatus();
    return status.connected;
  }
}
